/** @jsx jsx */
import { Box, jsx } from "theme-ui"
import { graphql, useStaticQuery } from 'gatsby'
import Feedback from './Feedback'

const Footer = () => {

  const query = graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          googleAnalyticsId
        }
      }
    }
  `

  const data = useStaticQuery(query)

  if (process.env.NODE_ENV === "development") {
    console.log('[Footer]', 'process.env.GOOGLE_ANALYTICS_ID', data?.site?.siteMetadata?.googleAnalyticsId)
  }

  return (
    <Box as="footer" variant="footer">
      <Feedback />
      Copyright &copy; Idan Izicovich {new Date().getFullYear()}. All rights reserved.
    </Box>
  )
}

export default Footer
