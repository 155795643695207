import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ColorModeButton = makeShortcode("ColorModeButton");
const DownloadMyCvButton = makeShortcode("DownloadMyCvButton");
const AnimatedArrow = makeShortcode("AnimatedArrow");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Hi, I'm Idan Izicovich`}</h1>
    <p>{`I'm a full-stack application development engineer
that creates high-end web & mobile applications for the next generation of
consumer & business facing companies.`}</p>
    <div className={'flex gap10'}>
    <ColorModeButton mdxType="ColorModeButton" />
    <DownloadMyCvButton style={{
        borderRadius: '0.5em',
        padding: '0.5rem 1rem',
        marginBottom: '1rem'
      }} mdxType="DownloadMyCvButton" />
    </div>
    <AnimatedArrow mdxType="AnimatedArrow" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      