import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Projects`}</h2>
    <ProjectCard title="Card Memory Game" link="https://github.com/idanizi/card-memory-game/" bg="linear-gradient(to right, #14D430 0%, #805ad58c 100%)" mdxType="ProjectCard">
      <p>{`Card memory game as part of the `}<a parentName="p" {...{
          "href": "https://github.com/florinpop17/app-ideas"
        }}>{`apps ideas challenge`}</a></p>
      <a href="https://github.com/florinpop17/app-ideas/blob/master/Projects/2-Intermediate/Card-Memory-Game.md" target="\_blank">Project definition ↗️</a><br />
      <a href="https://github.com/idanizi/card-memory-game/" target="_blank">Github repository ↗️</a>
    </ProjectCard>
    <ProjectCard title="WhatsApp Chrome Extension" link="https://github.com/idanizi/whatsapp-chrome-ext" bg="linear-gradient(to right, #14D430 0%, #805ad58c 100%)" mdxType="ProjectCard">
      <p>{`WhatsApp chrome extension for sending direct message to a phone number without the need
of saving it to your contacts.`}</p>
      <a href="https://github.com/idanizi/whatsapp-chrome-ext" target="_blank">Github repository ↗️</a><br />
📺 <a href="https://www.youtube.com/watch?v=vwduHhEJof8" target="_blank">YouTube Video (without sound) ↗️</a>
    </ProjectCard>
    <ProjectCard title="NodeJs Stress Tester" link="https://github.com/idanizi/node-stress-tester" bg="linear-gradient(to right, #14D430 0%, #805ad58c 100%)" mdxType="ProjectCard">
      <p>{`Lightweight NodeJs traffic stress tester that fires HTTP calls by your choice of load, to test
heavy duty traffic systems.`}</p>
    </ProjectCard>
    <ProjectCard title="Typescript NodeJs Starter Kit" link="https://github.com/idanizi/ts-nodejs-starter" bg="linear-gradient(to right, #14D430 0%, #805ad58c 100%)" mdxType="ProjectCard">
      <p>{`A dreamy ✨ yet simple starter kit for Typescript & NodeJs for any light / heavy duty applications.`}</p>
    </ProjectCard>
    <ProjectCard title="Idan Moves To TLV" link="https://sites.google.com/view/idanmovestotelaviv" bg="linear-gradient(to right, #14D430 0%, #805ad58c 100%)" mdxType="ProjectCard">
      <p>{`Website dedicated to my friends to help me find an apartment in Tel Aviv city.`}</p>
      <p>{`I had used it in 2019 to find my awesome 🤩 apartment!`}</p>
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      