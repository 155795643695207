import React from 'react'

export default function Spinner() {
    return (
        <div>
            <code>
                // todo: spinner
            </code>
        </div>
    )
}
