import React, { useEffect, useRef } from "react";
import { Parallax } from "react-spring/renderprops-addons.cjs";
import Layout from "@lekoarts/gatsby-theme-cara/src/components/layout";
import Hero from "../components/hero";
import Projects from "../components/projects";
import About from "../components/about";
import Contact from "../components/contact";
import OpenForHireModal from "../components/OpenForHireModal";
import "src/styles/globals.scss";
import config from "src/config";

const Cara = () => {
  const parallaxRef: any = useRef();

  const onScroll = () => {
    const position = parallaxRef.current.current / parallaxRef.current.space;
    const widget = document.querySelector("div.calendly-badge-widget");
    if (position > 1) {
      widget.classList.add("shrink");
    } else {
      widget.classList.remove("shrink");
    }
  };

  useEffect(() => {
    if (!parallaxRef.current || !parallaxRef.current.container) return;
    parallaxRef.current.container.onscroll = onScroll;
  });

  const offsets = [0, 1, 3, 4];
  const factors = [1, 2, 1, 1];

  return (
    <Layout>
      {config.is_open_for_hire_modal && <OpenForHireModal />}
      <Parallax pages={5} ref={parallaxRef}>
        <Hero offset={offsets[0]} factor={factors[0]} speed={1} />
        <Projects offset={offsets[1]} factor={factors[1]} speed={1} />
        <About offset={offsets[2]} factor={factors[2]} speed={1} />
        <Contact offset={offsets[3]} factor={factors[3]} speed={1} />
      </Parallax>
    </Layout>
  );
};

export default Cara;
