import React, { useState, useEffect, useRef } from 'react'
import Modal from './Modal'
import _ from 'lodash'
import Spinner from './Spinner'

const BODY_TEXT_LIMIT = 500
const NEW_LINE_LIMIT = 5

function isBodyValueValid(value: string) {
    return value.length <= BODY_TEXT_LIMIT && (value.match(/[\r|\t|\n]/img)?.length ?? 0) < NEW_LINE_LIMIT
}

export default function Feedback() {

    const text = {
        title_placeholder: "What do you think about this website? How could Idan do it better?",
        body_placeholder: "Description (Markdown is supported)..."
    }

    const [isModalVisible, setIsModalVisible] = useState(false)
    const [feedbackTitle, setFeedbackTitle] = useState("")
    const [feedbackBody, setFeedbackBody] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const feedbackTitleRef = useRef<HTMLInputElement>()

    useEffect(() => {
        if (isModalVisible) {
            feedbackTitleRef.current.focus()
        }

    }, [isModalVisible])

    const handleFeedbackTitleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        evt.preventDefault()
        const { value } = evt.target
        setFeedbackTitle(value)
    }

    const handleFeedbackBodyChange = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
        evt.preventDefault()
        const { value } = evt.target

        if (isBodyValueValid(value)) {
            setFeedbackBody(value)
        }
    }

    const isPayloadValid = ({ title, body }) => {
        return title?.length > 0;
    }

    const postFeedback = async (title: string, body: string) => {
        const repo_owner = process.env.GATSBY_GITHUB_OWNER
        const repo_name = process.env.GATSBY_GITHUB_REPO_NAME
        const token = process.env.GATSBY_GITHUB_TOKEN

        const payload = {
            title,
            body,
            labels: [
                "feedback"
            ]
        }

        if (!isPayloadValid(payload)) { return; }

        try {
            setIsLoading(true)

            const url = `https://api.github.com/repos/${repo_owner}/${repo_name}/issues`

            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Authorization": `token ${token}`,
                    "Accept": 'application/vnd.github.v3+json'
                },
                body: JSON.stringify(payload),
            })

            if (response.ok) {
                const result = await response.json()
                console.log('[feedback]', '[postFeedback]', 'success. result:', result)
            } else {
                console.log('[feedback]', '[postFeedback]', 'response not ok', response)
            }
        } catch (error) {
            console.error('[feedback]', '[postFeedback]', { title, body, error })
        } finally {
            setIsLoading(false)
        }
    }

    const handleFeedbackSubmit = () => {
        postFeedback(feedbackTitle, feedbackBody)
        setIsModalVisible(false)
    }

    const handleCloseModal = () => {
        setIsModalVisible(false)
    }




    return (
        <>
            <Modal isVisible={isModalVisible} onCloseClick={handleCloseModal}>
                <section>
                    <header>
                        <input type="text"
                            ref={feedbackTitleRef}
                            value={feedbackTitle}
                            style={{ fontStyle: feedbackTitle.length > 0 ? 'normal' : 'italic' }}
                            placeholder={text.title_placeholder} onChange={handleFeedbackTitleChange}
                        />
                    </header>
                    <article>
                        <textarea
                            value={feedbackBody}
                            style={{ fontStyle: feedbackBody.length > 0 ? 'normal' : 'italic' }}
                            placeholder={text.body_placeholder} onChange={handleFeedbackBodyChange} />

                        <span className="counter">
                            {`${feedbackBody.length} / ${BODY_TEXT_LIMIT}`}
                        </span>
                    </article>
                    <footer>
                        {isLoading
                            ?
                            <Spinner />
                            :
                            <button onClick={handleFeedbackSubmit}>Submit</button>
                        }
                    </footer>
                </section>
            </Modal>
            <div>
                <p>
                    What do you think of this website? I would love to get <a style={{cursor: 'pointer'}} onClick={() => setIsModalVisible(true)}>your feedback</a>!
            </p>
            </div>
        </>
    )
}
