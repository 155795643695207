import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <blockquote>
      <p parentName="blockquote">{`I'm a tech-savvy, team player and effective problem solver with
great communication skills. My experience in the industry had taught
me to be responsible, attentive, fast learner, and enjoy working with people.
I am looking to develop an interesting and challenging product with high market
impact.`}</p>
    </blockquote>
    <p>{`B.Sc. @ Software Engineering by `}<a href="https://in.bgu.ac.il/" target="_blank">{`Ben Gurion University of the Negev ↗️`}</a>{`.`}<br /><br /></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      