import React, { useRef, useState } from "react";
import "./OpenForHireModal.scss";
import Modal, { CloseIcon } from "../Modal";
import useOnClickOutside from "../../hooks/use-on-click-outside";
import { useIsMobile } from "src/helpers/responsive";
import confetti from "canvas-confetti";
import CallMeButton from "../CallMeButton";
import DownloadMyCvButton from "../DownloadMyCvButton";
import { open_for_hire_modal } from "src/lang/resources/en";
import config from "src/config";

export function InfoIcon() {
  return <i className="info-icon" />;
}

const renderConfetti = () => {
  confetti({
    particleCount: 100,
    spread: 70,
    origin: { y: 0.6 },
  });
};

export default function OpenForHireModal() {
  const [isVisible, setIsVisible] = useState(true);
  const isMobile = useIsMobile?.() ?? true;
  const ref = useRef();

  React.useLayoutEffect(() => {
    if (config.is_confetti) {
      setTimeout(renderConfetti, config.confetti_timeout_ms);
    }
  }, []);

  const handleClose = () => {
    setIsVisible(false);
  };

  useOnClickOutside(ref, handleClose);

  if (isMobile) return null;
  return (
    <Modal isVisible={isVisible} shouldHaveCloseIcon={false}>
      <section>
        <div className="content" ref={ref}>
          <CloseIcon onClick={handleClose} />
          <header>
            <h1>{open_for_hire_modal.title}</h1>
            {open_for_hire_modal.subtitle && (
              <h2 className="flex gap10">
                <InfoIcon />
                <span>{open_for_hire_modal.subtitle}</span>
              </h2>
            )}
          </header>
          <footer>
            {config.is_open_for_hire ? (
              <>
                <DownloadMyCvButton />
                <CallMeButton />
              </>
            ) : (
              <span>{open_for_hire_modal.footer}</span>
            )}
          </footer>
        </div>
      </section>
    </Modal>
  );
}
